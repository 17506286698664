import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreateUserPopup.module.scss';
import fetch from 'node-fetch';
import moment from 'moment';
import authenticator from 'authenticator';
import { confirmAlert } from 'react-confirm-alert'; // Import


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import { FaExclamationTriangle, FaLock, FaFemale, FaMale, FaUsers, FaQuestionCircle, FaLifeRing, FaEye, FaEyeSlash } from "react-icons/fa";

import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@material-ui/core/Switch";



class CreateUserPopup extends React.Component {

    state = {
    	"_id": null,
      "first_name": "",
      "sim_id": "",
      "birth_day": "",
      "last_comment": null,
    	"last_name": "",
    	"facebook_username": "",
    	"facebook_password": "",
      "follow_group_amount": 0,
      "move_account": "",
      "is_logged_in": true,
      "locked_account": false,
      "block_comment": false,
      "last_get_following": null,
      "posts_per_day": 7,
      "block_publish": false,
      "block_join_group": false,
      "facebook_id": 'NOT FOUND',
    	"seed": "",
      "proxy": "",
      "gender": "",
    	"error": "",
    	"success": "",
    	"selected": null,
      "loading_login": false,
      "loading_get_following": false,
      "last_friend_request": false,
      "image_url": false,
      "logs": [],
      "log_stats": {},
      "logging_stats": {},
      "profiles": [],

      buttons: [],
      extra_options: [],
      close_path: [],
      tabs: [],

    }

    refresh() {
    }
    componentWillReceiveProps(props) {
      if (props.match.params.profile_id !== this.props.match.params.profile_id) {
        this.setState({
          _id: null,
          "logs": [],
          "log_stats": {},
          "profiles": [],

          "_id": null,
          "first_name": "",
          "sim_id": "",
          "birth_day": "",
          "last_comment": null,
          "last_name": "",
          "facebook_username": "",
          "facebook_password": "",
          "follow_group_amount": 0,
          "move_account": "",
          "is_logged_in": true,
          "locked_account": false,
          "block_comment": false,
          "last_get_following": null,
          "last_friend_request": null,
          "posts_per_day": 7,
          "block_publish": false,
          "block_join_group": false,
          "facebook_id": 'NOT FOUND',
          "seed": "",
          "proxy": "",
          "gender": "",

          
        });
        setTimeout(() => {
          this.update_data(this.props);
        }, 0)
      }
    }
    componentWillMount() {
    	this.update_data(this.props);
    }

    fromNow(date) {
      // return moment(date).relativeTimeThreshold('m', 60);
      return (moment(date)).fromNow();
    }
    download_profile_image(name) {

      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-profile-image?access_token=${token}&id=${this.props.match.params.profile_id}&name=${name}`,
        {mode: 'cors'})
      .then(response => response.blob())
      .then(blob => {
            console.log(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
            var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'image.jpeg';
        a.click();
        window.URL.revokeObjectURL(url);



      });

    }
    update_data(props) {
    	var token = localStorage.getItem('user_token');

      if (this.props.match.params.profile_id) {

          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-profile?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
          .then(response => response.json())
          .then(data => {
          	this.setState({
  		    	"first_name": data.first_name,
            "logging_stats": data.logging_stats,
            "_id": data._id,
            "sim_id": data.sim_id,
            "birth_day": data.birth_day,
            "last_comment": data.last_comment,
  		    	"last_name": data.last_name,
  		    	"facebook_username": data.facebook_username,
            "block_comment": data.block_comment,
            "posts_per_day": data.posts_per_day,
            "block_publish": data.block_publish,
            "block_join_group": data.block_join_group,
            "last_get_following": data.last_get_following,
            "last_friend_request": data.last_friend_request,
  		    	"facebook_password": data.facebook_password,
            "follow_group_amount": data.follow_group_amount,
            "picture_files": data.picture_files,
  		    	"seed": data.seed,
            "gender": data.gender,
            "facebook_id": data.facebook_id,
            "post_from_id": data.post_from_id,
            "proxy": data.proxy,
            "is_logged_in": data.is_logged_in,
            "locked_account": data.locked_account,
            // "logs": data.logs,
            "log_stats": data.log_stats,
          	});
          })
          .catch(err => {

          });
      }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-profile-logs?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
          "logs": data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/user-conections?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            profiles: data,
          });
        })
        .catch(err => {

        });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/user-photos?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            user_photos: data,
          });
        })
        .catch(err => {

        });
    }

    handleUnlock() {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unlock?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('User unlocked2');
          this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }

    openProfile() {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/open-profile?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Open Browser and Login, please wait');
          // this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }
    stopProfileActivity() {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/stop-profile-activity?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Open Browser and Login, please wait');
          // this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }

    handleLogin() {
        var token = localStorage.getItem('user_token');

        this.setState({
          loading_login: true,
        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profile-login?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          this.setState({
            loading_login: false,
            image_url: data.image_url,
          });


          NotificationManager.success('Added to Login Que');

          this.props.tutorialEvent({action: 'login_trigger'})

           if (!data.image_url) {
              this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
           }
        })
        .catch(err => {
          NotificationManager.error('Error adding to Login Que');

          this.setState({
            error: "Error Login",
            success: "",
          });
        })
    }

    sendFriendRequest(profile_id) {      
      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/send-friend-request?access_token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: profile_id,
        })
      })
      .then(response => response.json())
      .then(data => {

        NotificationManager.success('Added to Login Que');

      })
      .catch(err => {
        NotificationManager.error('Error adding to Friend Que');

        this.setState({
          error: "Error Login",
          success: "",
        });
      })
    }
    sendFriendProfileCommentRequest(user_id, friend_user_id) {      
      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/send-friend-prifile-comment-request?access_token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user_id,
          friend_user_map: friend_user_id,
        })
      })
      .then(response => response.json())
      .then(data => {

        NotificationManager.success('Added to Login Que');

      })
      .catch(err => {
        NotificationManager.error('Error adding to Friend Que');

        this.setState({
          error: "Error Login",
          success: "",
        });
      })
    }

    handleResetAgent() {
        var token = localStorage.getItem('user_token');

        this.setState({
          loading_login: true,
        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reset-agent?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          this.setState({
            loading_login: false,
            image_url: data.image_url,
          });


          NotificationManager.success('Added to Login Que');

           if (!data.image_url) {
              this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
           }
        })
        .catch(err => {
          NotificationManager.error('Error adding to Login Que');

          this.setState({
            error: "Error Login",
            success: "",
          });
        })
    }
    handleScanGroup() {
        var token = localStorage.getItem('user_token');

        this.setState({
          loading_login: true,
        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/scan-posts?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          this.setState({
            loading_login: false,
            image_url: data.image_url,
          });


          NotificationManager.success('Added to Login Que');

           if (!data.image_url) {
              this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
           }
        })
        .catch(err => {
          NotificationManager.error('Error adding to Login Que');

          this.setState({
            error: "Error Login",
            success: "",
          });
        })
    }

    handleGetFollowing() {
        var token = localStorage.getItem('user_token');

        this.setState({
          loading_get_following: true,
        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profile-get-following?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          this.setState({
            loading_get_following: false,
            image_url: data.image_url,
          });


          NotificationManager.success('Added to Get following Que');

           if (!data.image_url) {
              this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
           }
        })
        .catch(err => {
          NotificationManager.error('Error adding to Get following Que');

          this.setState({
            error: "following Login",
            success: "",
          });
        })
    }

    handleSetAllCities() {
        var token = localStorage.getItem('user_token');

        // this.setState({
        //   loading_get_following: true,
        // });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/update-cities?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added to Que');
        })
        .catch(err => {
          NotificationManager.error('Error adding to Get following Que');

          this.setState({
            error: "following Login",
            success: "",
          });
        })
    }

    handleSetAllJobs() {
        var token = localStorage.getItem('user_token');

        // this.setState({
        //   loading_get_following: true,
        // });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/update-jobs?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added to Que');
        })
        .catch(err => {
          NotificationManager.error('Error adding to Get following Que');

          this.setState({
            error: "following Login",
            success: "",
          });
        })
    }

    handleCreateUserPopupClicked() {
		    var token = localStorage.getItem('user_token');

        if (!this.state.facebook_username || !this.state.facebook_password || !this.state.gender) {
          // this.state.validate_form = true;
          this.setState({validate_form: true});
          return 'not valid';
        }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-profile?access_token=${token}`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
  		    	first_name: this.state.first_name,
  		    	last_name: this.state.last_name,
  		    	facebook_username: this.state.facebook_username,
  		    	facebook_password: this.state.facebook_password,
            post_from_id: this.state.post_from_id,
            block_comment: this.state.block_comment,
            posts_per_day: this.state.posts_per_day,
            block_publish: this.state.block_publish,
            block_join_group: this.state.block_join_group,
            gender: this.state.gender,
  		    	seed: this.state.seed,
            proxy: this.state.proxy,
  		    	id: this.props.match.params.profile_id,
  		    })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success === false) {
            NotificationManager.error('Error adding profile');
            return;
          }

          if (!this.props.match.params.profile_id) {
            NotificationManager.success('Added profile successfully');
          } else {
            NotificationManager.success('Saved profile successfully');
          }

          this.props.tutorialEvent({action: 'success_create_user'})

          // this.props.updateSearch({path: [':lang', 'console', 'profile', 'edit', data._id], profile: data._id, "new-profile": Math.random()})
          this.props.updateSearch({path: [':lang', 'console', 'profile'], profile: data._id, "new-profile": Math.random()})
        })
        .catch(err => {
        	NotificationManager.error('Error adding profile');

        	this.setState({
        		error: "Error adding profile",
        		success: "",
        	});
        })
    }

    schedule_photo(type, image_hash) {
      // NotificationManager.error('ddddddd');
          var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/schedule-photo?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
            image_hash: image_hash,
            type: type,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success');
        })
        .catch(err => {
          NotificationManager.error('Error');
        })


    }

    handleDeleteCampaign() {


    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-profile?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted profile successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-profile": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting profile');

          this.setState({
            error: "Error deleting profile",
            success: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {
            
          }
        }
      ]
    });

      
    }

    buttons = [
      {
        label: 'Login',
        tool_tip: 'Login',
        class: 'login-button',
        action: () => {
          this.handleLogin();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        }
      },
      {
        label: 'Open Browser',
        tool_tip: 'Open Browser',
        class: 'open-browser-button',
        action: () => {
          this.openProfile();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        }
      },
      {
        label: 'Delete',
        tool_tip: 'Delete',
        class: 'delete-button',
        action: () => {
          this.handleDeleteCampaign();
        },
        should_display: () => {
          return this.props.match.params.profile_id;
        },
        class: 'danger'
      },          
    ];

    extra_options = [
      {
        label: 'Stop Profile Activity',
        tool_tip: 'Stop Profile Activity',
        action: () => {
          this.stopProfileActivity();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return !this.state.locked_account && this.props.match.params.profile_id && query.admin;
        }
      },
      {
        label: 'Unlock',
        tool_tip: 'Unlock',
        action: () => {
          this.handleUnlock();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.state.locked_account && this.props.match.params.profile_id && query.admin;
        }
      },
      {
        label: 'Reset User Agent',
        tool_tip: 'Reset User Agent',
        action: () => {
          this.handleResetAgent();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        }
      },
      {
        label: 'Scan Posts',
        tool_tip: 'Reset User Agent',
        action: () => {
          this.handleScanGroup();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        }
      },
      {        
        label: 'Get Following Groups',
        tool_tip: 'Get Following Groups',
        action: () => {
          this.handleGetFollowing();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        }
      },
      {        
        label: 'Set All Cities',
        tool_tip: 'Set All Cities',
        action: () => {
          this.handleSetAllCities();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);
          return query.admin;
        }
      },
      {        
        label: 'Set All Jobs',
        tool_tip: 'Set All Jobs',
        action: () => {
          this.handleSetAllJobs();
        },
        should_display: () => {
          var query = queryString.parse(this.props.location.search);
          return query.admin;
        }
      },
    ];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <div className="container">
            {this.state?.logging_stats?.is_invalid_password && <div className="row">
                          {JSON.stringify(this.state.logging_stats, null, 4) }

                <a  href={this.state?.logging_stats?.invalid_password_screenshot} target="_blank"><img style={{maxWidth: '100%'}} src={this.state?.logging_stats?.invalid_password_screenshot}/></a>
            </div>}
            <div className="row">
              <form autocomplete="off" onfocus="this.removeAttribute('readonly');"  className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">
                {queryString.parse(this.props.location.search).admin && <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('First Name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t('First Name')}
                    onChange={event => {this.setState({ first_name: event.target.value })}}
                    value={this.state.first_name}
                  />
                </div>}
                {queryString.parse(this.props.location.search).admin && <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Last Name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t('Last Name')}
                    onChange={event => {this.setState({ last_name: event.target.value })}}
                    value={this.state.last_name}
                  />
                </div>}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Username / phone')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                  <input
                    required={true}
                    type="text"
                    className="form-control"
                    placeholder={this.props.t('Email / Phone')}
                    onChange={event => {this.setState({ facebook_username: event.target.value })}}
                    value={this.state.facebook_username}
                    autocomplete="off"
                  />

                  <small id="emailHelp" className="form-text text-muted"><strong>id:</strong> {this.state.facebook_id}</small>
                  <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your facebook username`)}</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">פרסם מדף</label>
                  <input
                    required={true}
                    type="text"
                    className="form-control"
                    placeholder={`פרסם מדף`}
                    onChange={event => {this.setState({ post_from_id: event.target.value })}}
                    value={this.state.post_from_id}
                    autocomplete="off"
                  />

                  <small id="emailHelp" className="form-text text-muted"><strong>id:</strong> {this.state.facebook_id}</small>
                  <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your facebook username`)}</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Password')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>


<div className="input-group mb-3">
  <div className="input-group-prepend">
    {!this.state.show_password && <span className="input-group-text" id="basic-addon1" onClick={event => {this.setState({ show_password: true })}}><FaEye/></span>}
    {this.state.show_password && <span className="input-group-text" id="basic-addon1" onClick={event => {this.setState({ show_password: false })}}><FaEyeSlash/></span>}
  </div>
  <input
    required={true}
    type={this.state.show_password ? "text" : "password"}
    className="form-control"
    placeholder={this.props.t('Password')}
    onChange={event => {this.setState({ facebook_password: event.target.value })}}
    value={this.state.facebook_password}
    autocomplete="off"
  />
</div>


                  <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your facebook password`)}</small>
                </div>
                
                {<div>
                    <Switch
                      classes={this.props.classes}
                      checked={this.state.has_2_step_auth}
                      onClick={(e) => { this.setState({
                        has_2_step_auth: !this.state.has_2_step_auth,
                      }) }}
                    />
                    {this.props.t('I have 2 step authenticatication')} ({this.props.t('Not requied')}) <FaLifeRing onClick={this.props.updateSearch.bind(this, {"current-tutoral": "2-step-auth", "step": "0", tutorial: "1"})} data-tip={this.props.t('Click Here To Learn How to setup 2 step authenticatication')}/>
                </div>}


                {this.state.has_2_step_auth && <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('2step auth Seed')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t('XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX')}
                    onChange={event => {this.setState({ seed: event.target.value })}}
                    value={this.state.seed}
                  />
                  <small id="emailHelp" className="form-text text-muted">{this.props.t(`if 2 step auth is enabled`)}</small>
                </div>}

                {false && <div>
                    <Switch
                      classes={this.props.classes}
                      checked={this.state.has_proxy}
                      onClick={(e) => { this.setState({
                        has_proxy: !this.state.has_proxy,
                      }) }}
                    />
                    {this.props.t('I want to use a proxy')} <FaLifeRing data-tip={this.props.t('Click Here To Learn How to set up a proxy')}/>
                </div>}
                {false && this.state.has_proxy && <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Proxy')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={'([user]:[pass]:)[host]:[port]'}
                    onChange={event => {this.setState({ proxy: event.target.value })}}
                    value={this.state.proxy}
                  />
                </div>}















                {<div>
                    <label htmlFor="exampleInputEmail1" style={{
                    opacity: this.props.user.personal_plan ? 0.7 : 1,
                  }}>

                    <Switch
                      classes={this.props.classes}
                      checked={!this.state.block_comment}
                      onClick={(e) => { this.state.block_comment = !this.state.block_comment; this.setState({
                        post: this.state.post,
                      }) }}
                      disabled={this.props.user.personal_plan}
                    />
                    {this.props.user.personal_plan && <FaLock/>}
                    {this.props.t('Allow User To Comment On Posts')}
                    <FaQuestionCircle data-tip={this.props.t('For higher porformance turn on automatic comments')}/>
                  </label>

                    {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
                </div>}


                {<div>
                    <Switch
                      classes={this.props.classes}
                      checked={!this.state.block_join_group}
                      onClick={(e) => { this.state.block_join_group = !this.state.block_join_group; this.setState({
                        post: this.state.post,
                      }) }}
                    />
                    {this.props.t('Allow user to join groups automatically')}
                </div>}

                {<div>
                    {!this.props.user.personal_plan && <div>
                    <Switch
                      classes={this.props.classes}
                      checked={!this.state.block_publish}
                      onClick={(e) => { this.state.block_publish = !this.state.block_publish; this.setState({
                        post: this.state.post,
                      }) }}
                    />
                    {this.props.t('Allow user to post posts')}
                    </div>}

                    {!this.state.block_publish && <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{this.props.t('User daily post limit')}</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder={'4'}
                        max={30}
                        onChange={event => {this.setState({ posts_per_day: event.target.value })}}
                        value={this.state.posts_per_day}
                      />

                      <small id="emailHelp" className="form-text text-muted">({this.props.t(`If the profile has existed for more then 1 year, you can post between 30 to 60 posts per day`)})</small>
                    </div>}

                </div>}

                <div className="form-group" className={"align-center text-center"}>
                  <label style={{fontSize: '20px'}} htmlFor="exampleInputEmail1" className={`align-center text-center`}>{this.props.t('Profile Gender')} <span style={{color: ''}} className={`${this.state.validate_form && !this.state.gender && 'text-danger'}`}>({this.props.t('Required')})</span></label>
                  
                  <div className="input-group mb-3 align-center" style={{paddingTop: '10px', fontWeight: 'bold'}}>
                    <div className="input-group-prepend" style={{margin: 'auto'}}>
                      {/*<span onClick={() => { this.state.gender = 'ALL'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Both Male And Female")} className="input-group-text" id="basic-addon1" style={{
                        padding: 0,
                        margin: 0,
                        color: this.state.gender === 'ALL' ? 'rgb(35 151 66)' : '#000',
                        fontSize: '32px',

                      }}><FaUsers/></span>*/}
                      <span onClick={() => { this.state.gender = 'FEMALE'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Female")} className="input-group-text" id="basic-addon1" style={{
                        padding: 0,
                        margin: 0,
                        color: this.state.gender === 'FEMALE' ? 'rgb(35 151 66)' : '#000',
                        fontSize: '32px',
                      }}><FaFemale/></span>
                      <span onClick={() => { this.state.gender = 'MALE'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Male")} className="input-group-text" id="basic-addon1" style={{
                        padding: 0,
                        margin: 0,
                        color: this.state.gender === 'MALE' ? 'rgb(35 151 66)' : '#000',
                        fontSize: '32px',
                      }}><FaMale/></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button type="submit" className="btn btn-warning" onClick={this.handleCreateUserPopupClicked.bind(this)}>
                {this.props.edit ? this.props.t('Update') : this.props.t('Create')}
              </button>
            </div>
          </div>
        </div>,
      },
      {
        label: 'Logs',
        active: true,
        should_display: () => {

          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        },        
        content: () => <div>
          {this.props.match.params.profile_id && <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="card">
                          <div className="card-body">
                              <h6 className="card-title">Timeline</h6>
                              <div id="content">
                                  <ul className="timeline">
                                      {this.state.logs && this.state.logs.map((log) => (<li className="event" data-date={this.fromNow(log.created_at)}>
                                          <h3>
                                            {log.user && <img width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + log.user._id} g="img/user1.png" alt=""/>}
                                            {log.action}
                                          </h3>
                                          <p>{log.message}</p>
                                          <p><a target="blank" href={log.image_url}><img src={log.image_url} style={{maxWidth: "100%"}}/></a></p>
                                      </li>))}
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>}
        </div>,
      },
      {
        label: 'Data',
        active: true,
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        },                
        content: () => <div>
          {this.props.match.params.profile_id && <div class="container">
          <div class="row">
            <div class="col-12">
              <ul className="list-group" style={{color: "black"}}>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Last get following')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.last_get_following ? moment(this.state.last_get_following).fromNow() : 'Never'}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Last friend request')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.last_friend_request ? moment(this.state.last_friend_request).fromNow() : 'Never'}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Seed Code')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.seed && authenticator.generateToken(this.state.seed)}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Following Groups Amount')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.follow_group_amount}</span>
                </li>
                {this.state.picture_files && <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Backup Profile Images')}

                  {this.state.picture_files.map((name, index) => <span onClick={this.download_profile_image.bind(this, name)} className="badge badge-success badge-pill badge-blue">{name}</span>)}
                </li>}
              </ul>
            </div>
            <div class="col-12">
              <ul className="list-group" style={{color: "black"}}>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Facebook ID')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.facebook_id}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Tag Code')}
                  <span className="badge badge-primary badge-pill badge-blue">@[[{this.state.first_name} {this.state.last_name},{this.state.facebook_id}]]</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Sim #')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.sim_id}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Birthday')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.birth_day}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {this.props.t('Last Comment')}
                  <span className="badge badge-primary badge-pill badge-blue">{this.state.last_comment ? moment(this.state.last_comment).fromNow() : 'never'}</span>
                </li>
              </ul>
            </div>
          </div>
          </div>}
        </div>,
      },
      {
        label: 'Friends',
        active: true,
        should_display: () => {
          var query = queryString.parse(this.props.location.search);

          return this.props.match.params.profile_id && query.admin;
        },                
        content: () => <div>
          {this.props.match.params.profile_id && <div class="container">
            <div className="row">

              <div class="col-12">
                <ul className="list-group" style={{color: "black"}}>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    {this.props.t('Last friend request')}
                    <span className="badge badge-primary badge-pill badge-blue">{this.state.last_friend_request ? moment(this.state.last_friend_request).fromNow() : 'Never'}</span>
                  </li>
                </ul>
              </div>
              <div class="col-12 text-center">
                <button onClick={this.sendFriendRequest.bind(this, this.state._id)} className="btn btn-warning">{this.props.t('Friend Request')}</button>
              </div>



              <div className="col-12">
                <ul className="list-group" style={{color: "black"}}>
                  {this.state.profiles.map((profile) => (<li className="list-group-item d-flex justify-content-between align-items-center">
                    {profile.other_party.first_name} {profile.other_party.last_name} {profile.other_party.account}
                    <span className="badge badge-primary badge-pill badge-blue">{moment(profile.other_party.last_friend_request).fromNow()}</span>
                    <span className="badge badge-primary badge-pill badge-blue">{profile.status}_{profile.turn}</span>
                    <span className="badge badge-primary badge-pill badge-blue">{profile.other_party.locked_account ? "LOCKED" : "KK"}</span>
                  </li>))}
                </ul>
              </div>
            </div>
          </div>}
        </div>,
      },
      {
        label: 'Photos',
        active: true,
        should_display: () => {
          return this.state.user_photos && this.state.user_photos.scanned_facebook_profile;
        },                
        content: () => <div>
          {this.props.match.params.profile_id && <div class="container">
            <div className="row">
              <h3>{this.props.t('Profile Photos')}</h3>
              <ul>
                {this.state.user_photos._profile_photos.map((hash) => (<li>
                  <img width={'200px'} src={"https://eoz.ams3.digitaloceanspaces.com/scanned_profile_images/" + hash}/>
                  {!this.state.user_photos.slot_map[hash] && <button onClick={this.schedule_photo.bind(this, 'profile', hash)}>{this.props.t('Schedule Photo')}</button>}
                </li>))}
              </ul>

              <h3>{this.props.t('Cover  Photos')}</h3>
              <ul>
                {this.state.user_photos._cover_photos.map((hash) => (<li>
                  <img width={'200px'} src={"https://eoz.ams3.digitaloceanspaces.com/scanned_profile_images/" + hash}/>
                  {!this.state.user_photos.slot_map[hash] && <button onClick={this.schedule_photo.bind(this, 'cover', hash)}>{this.props.t('Schedule Photo')}</button>}
                </li>))}
              </ul>
            </div>
          </div>}
        </div>,
      },
    ];


    render() {
    	const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_user_${this.props.match.params.profile_id}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-user-popup',
        loading: this.props.match.params.profile_id ? !(this.state._id) : false,
        title: this.props.match.params.profile_id ? `Edit Profile` : 'Add New Profile v2',
        sub_title: this.props.match.params.profile_id ? `${this.state.first_name} ${this.state.last_name}` : null,
        // loading: false,
      });
      return (<div></div>);
        
        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              <div className="bg-primary p-5 text-white rounded-3 create-profile-popup" id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit profile') : t('Create profile')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  {this.props.match.params.profile_id && <div class="row">
                    <div class="col-6">
                      <ul className="list-group" style={{color: "black"}}>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Last get following')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.last_get_following ? moment(this.state.last_get_following).fromNow() : 'Never'}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Seed Code')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.seed && authenticator.generateToken(this.state.seed)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Following Groups Amount')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.follow_group_amount}</span>
                        </li>
                        {this.state.picture_files && <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Backup Profile Images')}

                          {this.state.picture_files.map((name, index) => <span onClick={this.download_profile_image.bind(this, name)} className="badge badge-success badge-pill badge-blue">{name}</span>)}
                        </li>}
                      </ul>
                    </div>
                    <div class="col-6">
                      <ul className="list-group" style={{color: "black"}}>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Facebook ID')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.facebook_id}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Tag Code')}
                          <span className="badge badge-primary badge-pill badge-blue">@[[{this.state.first_name} {this.state.last_name},{this.state.facebook_id}]]</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Sim #')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.sim_id}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          {this.props.t('Birthday')}
                          <span className="badge badge-primary badge-pill badge-blue">{this.state.birth_day}</span>
                        </li>
                      </ul>
                    </div>
                  </div>}



                  <div className="error">{this.props.t(this.state.error)}</div>
                  <div className="success">{this.props.t(this.state.success)}</div>


                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('First Name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t('First Name')}
                      onChange={event => {this.setState({ first_name: event.target.value })}}
                      value={this.state.first_name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Last Name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t('Last Name')}
                      onChange={event => {this.setState({ last_name: event.target.value })}}
                      value={this.state.last_name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Username')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t('Username')}
                      onChange={event => {this.setState({ facebook_username: event.target.value })}}
                      value={this.state.facebook_username}
                    />
                    id: {this.state.facebook_id}
                    <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your facebook username`)}</small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Password')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder={this.props.t('Password')}
                      onChange={event => {this.setState({ facebook_password: event.target.value })}}
                      value={this.state.facebook_password}
                    />
                    <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your facebook password`)}</small>
                  </div>

                  {<div>
                      <Switch
                        classes={this.props.classes}
                        checked={!this.state.has_2_step_auth}
                        onClick={(e) => { 
                          this.setState({
                            has_2_step_auth: !this.state.has_2_step_auth,
                          }) }}
                      />
                      {this.props.t('I have 2 step authenticatication')}
                  </div>}


                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('2step auth Seed')}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t('XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX')}
                      onChange={event => {this.setState({ seed: event.target.value })}}
                      value={this.state.seed}
                    />
                    <small id="emailHelp" className="form-text text-muted">{this.props.t(`if 2 step auth is enabled`)}</small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Proxy')}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={'([user]:[pass]:)[host]:[port]'}
                      onChange={event => {this.setState({ proxy: event.target.value })}}
                      value={this.state.proxy}
                    />
                  </div>

                  {<div>
                      {this.props.t('Allow Comment')}
                      <Switch
                        classes={this.props.classes}
                        checked={!this.state.block_comment}
                        onClick={(e) => { this.state.block_comment = !this.state.block_comment; this.setState({
                          post: this.state.post,
                        }) }}
                      />
                  </div>}

                  {<div>
                      {this.props.t('Allow Join Group')}
                      <Switch
                        classes={this.props.classes}
                        checked={!this.state.block_join_group}
                        onClick={(e) => { this.state.block_join_group = !this.state.block_join_group; this.setState({
                          post: this.state.post,
                        }) }}
                      />
                  </div>}

                  <div className="form-group">
                    <h4 htmlFor="exampleFormControlTextarea1">{this.props.t('Gender')}</h4>
                    <div className="input-group mb-3 align-center">
                      <div className="input-group-prepend" style={{margin: 'auto'}}>
                        {/*<span onClick={() => { this.state.gender = 'ALL'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Both Male And Female")} className="input-group-text" id="basic-addon1" style={{
                          padding: 0,
                          margin: 0,
                          color: this.state.gender === 'ALL' ? 'rgb(35 151 66)' : '#000',
                          fontSize: '32px',

                        }}><FaUsers/></span>*/}
                        <span onClick={() => { this.state.gender = 'FEMALE'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Female")} className="input-group-text" id="basic-addon1" style={{
                          padding: 0,
                          margin: 0,
                          color: this.state.gender === 'FEMALE' ? 'rgb(35 151 66)' : '#000',
                          fontSize: '32px',
                        }}><FaFemale/></span>
                        <span onClick={() => { this.state.gender = 'MALE'; this.setState({gender: this.state.gender}) }} data-tip={this.props.t("Male")} className="input-group-text" id="basic-addon1" style={{
                          padding: 0,
                          margin: 0,
                          color: this.state.gender === 'MALE' ? 'rgb(35 151 66)' : '#000',
                          fontSize: '32px',
                        }}><FaMale/></span>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary" onClick={this.handleCreateUserPopupClicked.bind(this)}>
                    {this.props.edit ? t('Update') : t('Create')}
                  </button>

                  {this.state.image_url && <div className="bg-blue py-3 my-3 rounded-3 d-flex">
                    <img className="mx-4" width="100%" src={this.state.image_url} />
                  </div>}

                  {this.state.loading_login && <button className="btn w-75 fw-bold bg-success py-3 px-5 mt-4 text-primary rounded rounded-pill">
                      <img class="alignnone" src="https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif" alt="" width="48" height="48"/>
                    </button>
                  }

                  {!this.state.loading_login && this.props.edit && !this.state.locked_account /*&& !this.state.is_logged_in*/ && <button onClick={this.handleLogin.bind(this)} type="submit" className="btn w-75 fw-bold bg-success py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Login')}</button>}


                  {!this.state.loading_login && this.props.edit && !this.state.locked_account /*&& !this.state.is_logged_in*/ && <button onClick={this.handleResetAgent.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Reset User Agent')}</button>}
                  
                  {!this.state.loading_login && this.props.edit && this.state.locked_account && <button onClick={this.handleUnlock.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill"><FaLock/> {this.props.t('Account is locked, update account and click here')} <FaLock/></button>}
                  {!this.state.loading_login && this.props.edit && this.state.locked_account && <button onClick={this.openProfile.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill"><FaLock/> {this.props.t('Open Browser to Unlock')} <FaLock/></button>}
                  
                  {!this.state.loading_login && this.props.edit && <button onClick={this.stopProfileActivity.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill"><FaLock/> {this.props.t('Stop Profile Activity')} <FaLock/></button>}
                  
this.stopProfileActivity.bind(this)
{this.props.t('Stop Profile Activity')}

onClick={this.stopProfileActivity.bind(this)}
{this.props.t('Open Browser to Unlock')}

onClick={this.handleUnlock.bind(this)}
{this.props.t('Unlock')}

onClick={this.handleLogin.bind(this)}
{this.props.t('Login')}

onClick={this.handleResetAgent.bind(this)}
{this.props.t('Reset User Agent')}

onClick={this.handleDeleteCampaign.bind(this)}
{this.props.t('Delete')}

                  {this.props.edit && <button onClick={this.openProfile.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill"><FaLock/> {this.props.t('Open Browser to Unlock')} <FaLock/></button>}
                  

                  
                  {this.props.edit && <button onClick={this.handleDeleteCampaign.bind(this)} type="submit" className="btn w-75 fw-bold bg-danger py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Delete')}</button>}

                  {this.state.loading_get_following && <button className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill">
                      <img class="alignnone" src="https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif" alt="" width="48" height="48"/>
                    </button>
                  }
                  {!this.state.loading_get_following && this.props.edit && this.state.is_logged_in && <button onClick={this.handleGetFollowing.bind(this)} type="submit" className="btn w-75 fw-bold bg-warning py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Get Following Groups')}</button>}

                  <div className="row">
                    <div className="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.profiles.map((profile) => (<li className="list-group-item d-flex justify-content-between align-items-center">
                          {profile.first_name} {profile.last_name}
                          <span className="badge badge-primary badge-pill badge-blue">{profile.friend_map.status}</span>
                          {profile.friend_map.status !== 'friends' && <button className="btn btn-primary btn-sm" onClick={this.sendFriendRequest.bind(this, profile.friend_map._id)}>{this.props.t('Send Friend Request')}</button>}
                          {!profile.friend_map.commented[profile.friend_map.user_num].posted && profile.friend_map.status === 'friends' && <button className="btn btn-primary btn-sm" onClick={this.sendFriendProfileCommentRequest.bind(this, profile._id, profile.friend_map._id)}>{this.props.t('Comment on profile picture')}</button>}
                        </li>))}
                      </ul>
                    </div>
                  </div>

                  {this.props.match.params.profile_id && <div className="container timeline-container">
                      {Object.keys(this.state.log_stats).map((key) => (<div><strong>{key}:</strong> {this.state.log_stats[key]}</div>)) }
                      <br/>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="card">
                                  <div className="card-body">
                                      <h6 className="card-title">Timeline</h6>
                                      <div id="content">
                                          <ul className="timeline">
                                              {this.state.logs && this.state.logs.map((log) => (<li className="event" data-date={this.fromNow(log.created_at)}>
                                                  <h3>
                                                    {log.user && <img width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + log.user._id} g="img/user1.png" alt=""/>}
                                                    {log.action}
                                                  </h3>
                                                  <p>{log.message}</p>
                                                  <p><a target="blank" href={log.image_url}><img src={log.image_url} style={{maxWidth: "100%"}}/></a></p>
                                              </li>))}
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>}



              </div>

		  </div>
        );
    }
}

CreateUserPopup.propTypes = {};

CreateUserPopup.defaultProps = {};

export default withRouter(CreateUserPopup);
















