import React from 'react';
import PropTypes from 'prop-types';
import styles from './PostPopup.module.scss';
import fetch from 'node-fetch';
import { confirmAlert } from 'react-confirm-alert'; // Import

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import moment from 'moment';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';


import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";


class PostPopup extends React.Component {

    state = {
    	post: null,
    	selected: null,
        selected_campaign: null,
        // show: 'settings',
        show: 'comment_logs',
        campaigns: [],
        posts: [],
        logs: [],
        comments: [],
    }

    refresh() {

    }

    get_posts() {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${this.state.selected_campaign}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                posts: data,
            });
        })
        .catch(err => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.post && this.state.post.settings.edit_campaign && this.state.post.settings.edit_campaign !== this.state.selected_campaign) {
            this.setState({
                selected_campaign: this.state.post.settings.edit_campaign,
            })
            setTimeout(() => {
                this.get_posts();
            }, 1);
        }
    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-slot?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            data.settings = data.settings || {};
        	this.setState({
        		post: data,
        	});
        })
        .catch(err => {

        });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-slot/logs?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(logs => {
            this.setState({
                logs: logs,
            });
        })
        .catch(err => {

        });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-slot/comments?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(comments => {
            this.setState({
                comments: comments,
            });
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                campaigns: data,
            });
        })
        .catch(err => {

        });

    }

    refresh_status(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/refresh-status?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {

        });
    }

    refresh_reply(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/refresh-fresh?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		post: data,
        	});
        })
        .catch(err => {

        });
    }

    post_now (props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/post-now?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    post_comment_now (comment) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/post-comment-now?access_token=${token}&post=${this.state.selected}&comment=${comment}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    generate_comment_tasks (props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/generate-comment-tasks?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    reply_now(props, another_user) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reply-now?access_token=${token}&post=${this.state.selected}${another_user ? '&friend=true' : ''}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    delete_post(props) {

        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {

            var token = localStorage.getItem('user_token');

            fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/auto-delete-post?access_token=${token}&post=${this.state.selected}`, {})
            .then(response => response.json())
            .then(data => {
                NotificationManager.success('Yayyy..', 'Added to que');
            })
            .catch(err => {
                NotificationManager.error('Yayyy..', 'Error addeding to que');
            });
              }
            },
            {
              label: 'No',
              onClick: () => {
                
              }
            }
          ]
        });

        
    }
    remove_post(props) {

        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {

            var token = localStorage.getItem('user_token');



                fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/remove-post?access_token=${token}`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: this.state.post._id,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    NotificationManager.success('success', 'removed post');
                })
                .catch(err => {

                });




              }
            },
            {
              label: 'No',
              onClick: () => {
                
              }
            }
          ]
        });

        
    }

    random_comment(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/random-comment?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    trigger_edit(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/trigger-edit?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    unlock_comment(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unlock-comment?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    pump_existing_post(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/pump-existing-post?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
            NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    componentWillReceiveProps(props) {

        var id = this.props.location.pathname.split('/').pop()

    	
    	if (id !== this.state.selected) {

            this.setState({
                selected: id,
                post: null,
            });

			setTimeout(() => {
				this.update_data(props);	
			}, 0)
    	}
    }

    componentWillMount(props) {
    	this.setState({
    		selected: this.props.location.pathname.split('/').pop(),
    	});
		
		setTimeout(() => {
			this.update_data(this.props);	
		}, 0)
    }

    check_if_pending(date) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/check-pending?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    fromNow(date) {
    	// return moment(date).relativeTimeThreshold('m', 60);
    	return (moment(date)).fromNow();
    }

    save_settings(props) {

      var token = localStorage.getItem('user_token');
      var query = queryString.parse(this.props.location.search);

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-post-settings?access_token=${token}&post=${this.state.selected}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state.post.settings)
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('success', 'saved settings');

        })
        .catch(err => {

        });

    }

    buttons = [
      {
        label: 'Post Now',
        tool_tip: 'Login',
        action: () => {
          this.post_now();
        },
        should_display: () => {
          return !this.state.post.posted;
        }
      },     
      {
        label: 'Check if still pending',
        tool_tip: 'Login',
        action: () => {
          this.check_if_pending();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status.indexOf('pending') > -1;
        }
      },     
      {
        label: 'Delete Post',
        tool_tip: 'Delete Post',
        action: () => {
          this.delete_post();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status === 'published';
        }
      },     
      {
        label: 'Delete Post 2',
        tool_tip: 'Delete Post 2',
        action: () => {
            return true;
          this.remove_post();
        },
        should_display: () => {
          return this.state.post && !this.state.post.submitted_post;
        }
      },     
      {
        label: 'Trigger Edit',
        tool_tip: 'Trigger Edit',
        action: () => {
          this.trigger_edit();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && this.state.post.settings.should_edit && !this.state.post.edited;
        }
      },
      {
        label: 'Unlock Comments',
        tool_tip: 'Unlock Comments',
        action: () => {
          this.unlock_comment();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.locked_comment;
        }
      },
      {
        label: 'Show Post',
        tool_tip: 'Show Post',
        action: () => {
            if (this.state.post.group.group_type === 'page') {
                window.open(`https://facebook.com/${this.state.post.group.group_id}/posts/${this.state.post.submitted_post.facebook_post_id}`, "_blank");
            } else {
                window.open(`https://facebook.com/groups/${this.state.post.group.group_id}/posts/${this.state.post.submitted_post.facebook_post_id}`, "_blank");
            }
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.facebook_post_id;
        }
      },
      // {
      //   label: 'Post Now',
      //   tool_tip: 'Login',
      //   class: 'login-button',
      //   action: () => {

      //   },
      //   should_display: () => {
      //     return !this.state.post.posted;
      //   }
      // },     
    ];

    extra_options = [
      {
        label: 'Post Now (already posted)',
        tool_tip: 'Post Now',
        class: 'warning',
        action: () => {
          this.post_now();
        },
        should_display: () => {
          return this.state.post.posted;
        }
      },
      {
        label: 'Refresh Reply',
        tool_tip: 'Refresh Reply',
        action: () => {
          this.refresh_reply();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status === 'published';
        }
      },
      {
        label: 'Reply',
        tool_tip: 'Reply',
        action: () => {
          this.reply_now();
        },
        should_display: () => {
          return this.state.post.posted;
        }
      },
      {
        label: 'Check status',
        tool_tip: 'Check status',
        action: () => {
          this.refresh_status();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status === 'published';
        }
      },
      {
        label: 'Trigger Edit (again)',
        tool_tip: 'Trigger Edit (again)',
        class: 'login-button',
        action: () => {
          this.trigger_edit();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && this.state.post.settings.should_edit && this.state.post.edited;
        }
      },
      {
        label: 'Create Comments',
        tool_tip: 'Create Comments',
        action: () => {
            this.generate_comment_tasks();
        },
        should_display: () => {
          return this.state.post.submitted_post && this.state.post.submitted_post.facebook_post_id;
        }
      },
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>

          <a classname="text-background d-block m-1 cadre border border-2 border-secondary text-center position-relative" style={{width: '100%', border: '1px dashed gray', backgroundColor: 'white', padding: '0.5rem 1rem', marginRight: '1.5rem', marginBottom: '1.5rem', cursor: 'move', float: 'left', opacity: 1}}>
            {(this.state.post.post && this.state.post.post.type === 'text-background' ) && <span style={{background: '#d37554'}} classname="text-background">.{this.state.post.post.post_content}</span>}
            {(this.state.post.post && !this.state.post.post.type || this.state.post.post && this.state.post.post.type === 'image' ) && <span><img style={{"max-width": '500px'}} src={this.state.post.post.media_url}/></span>}
              {(this.state.post.post && !this.state.post.post.type || this.state.post.post && this.state.post.post.type === 'album' ) && <span><img style={{"max-width": '500px'}} src={this.state.post.post.media_url.split(',')[0]}/></span>}
          </a>

            <div class="row">
                <div class="col-12">
                    {this.state.post.submitted_post && <div>{this.props.t('Embed will only show if post is public')}</div>}
                  {this.state.post.submitted_post && <iframe
                    src={"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNetanyahu%2Fposts%2F"+this.state.post.submitted_post.facebook_post_id+"&show_text=false&width=500"} 
                    width="500" 
                    height="300" 
                    style={{
                      border: 'none', 
                      overflow: 'hidden'
                    }} 
                    scrolling="no" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>}
                </div>
            </div>






                    {this.state.post.post_type === 'existing' && <div>
                        <div class="row">
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Page Type')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.group && this.state.post.group.group_type}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Comments')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.poeple_comments.length : ''}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Comment Group')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.settings.comment_group}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Reply Count')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.replied_people.length : ''}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Status')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.status}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Locked Pump')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.locked_pump ? "Yes" : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('submitted_post_id')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post._id}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('last status')}
                                        {this.state.post.submitted_post && this.state.post.submitted_post.last_check_reaction_date && <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && (moment(this.state.post.submitted_post.last_check_reaction_date)).fromNow()}</span>}
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Detected')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? 'Yes' : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Profile')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.user ? `${this.state.post.user.first_name} ${this.state.post.user.last_name}` : 'No Profile'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Group')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.group ? `${this.state.post.group.name}` : 'No Group'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div>{this.props.t('Embed will only show if post is public')}</div>
                              {<iframe
                                src={"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNetanyahu%2Fposts%2F"+this.state.post.post_url_id+"&show_text=false&width=500"} 
                                width="500" 
                                height="500" 
                                style={{
                                  border: 'none', 
                                  overflow: 'hidden'
                                }} 
                                scrolling="no" 
                                frameborder="0" 
                                allowfullscreen="true" 
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                              ></iframe>}
                            </div>
                        </div>
                    </div>}


                    {this.state.post.post_type === 'new' && <div>
                        <div class="row">
                            <div class="col-12">
                                <h3>{this.props.t('Post Details')}</h3>
                                <ul className="list-group" style={{color: "black"}}>
                                    {this.state.post.campaign && this.state.post.post && this.state.post && <li onClick={this.props.updateSearch.bind(this, {campaign: this.state.post.campaign._id, path: [':lang', 'console', 'post', 'edit', this.state.post.post._id]})} className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('ID')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.post._id}</span>
                                    </li>}
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Campaign')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post?.campaign?.name}</span>
                                    </li>
                                    {/*<li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Type')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.post_type}</span>
                                    </li>*/}
                                    {this.state.post && this.state.post.post && <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Type')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.post.type}</span>
                                    </li>}
                                    {this.state.post && this.state.post.post && <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Media URL')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.post.media_url}</span>
                                    </li>}
                                    {this.state.post && this.state.post.post && <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Group URL')} {this.state.post.group.group_id}
                                        <span className="badge badge-primary badge-pill badge-blue"><a href={`https://facebook.com/${this.state.post.group.group_id}`} target="_blank"> {this.state.post.group.group_id} </a></span>
                                    </li>}
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('User')} {this.state?.post?.user._id}
                                        <span onClick={this.props.updateSearch.bind(this, {profile: this.state.post.user._id})} className="badge badge-primary badge-pill badge-blue">{this.state.post.user.first_name} {this.state.post.user.last_name}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>}





        </div>,
        footer: () => <div>
        </div>,
      },
      {
        label: 'Logs',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
            <div><h2>Logs</h2><div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title">Timeline</h6>
                                <div id="content">
                                    <ul className="timeline">
                                        {this.state.logs.map((log) => (<li className="event" data-date={this.fromNow(log.created_at)}>
                                            <h3>
                                                {log.user && <img width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + log.user._id} g="img/user1.png" alt=""/>}
                                                {log.action}
                                            </h3>
                                            <p>{log.message}</p>
                                            <p><a target="blank" href={log.image_url}><img src={log.image_url} style={{maxWidth: "100%"}}/></a></p>
                                        </li>))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
        </div>,
        footer: () => <div>
        </div>,
      },
      {
        label: 'Comment Logs',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
            <div>
            <h2>{this.props.t('Comment Logs')}</h2>

            <ul style={{"text-align": "left"}}>
            {this.state.comments && this.state.comments.filter((comment) => {
                return !comment.parent_comment;
            }).map((comment) => (<li>
                <div>{this.fromNow(comment.post_at)}</div>
                <div>
                    {comment.user && <img onClick={this.post_comment_now.bind(this, comment._id)} style={{opacity: comment.posted ? '1' : '0.5'}} width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + comment.user} g="img/user1.png" alt=""/>}
                    {comment.text}
                </div>
                <ul>
                    {this.state.comments.filter((child_comment) => {
                        return child_comment.parent_comment === comment._id;
                    }).map((comment) => (<li>
                        <div>
                            {comment.user && <img onClick={this.post_comment_now.bind(this, comment._id)} style={{opacity: comment.posted ? '1' : '0.5'}} width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + comment.user} g="img/user1.png" alt=""/>}
                            {comment.text}

                            <div class="emoji-section" style={{width: '90%'}}>
                               <div class="emoji-container" style={{height: 'auto'}}>
                                  {comment.reaction_id == 1 && <div style={{float: 'none'}} class="emoji like">
                                     <div class="icon" data-title="Like"></div>
                                  </div>}
                                  {comment.reaction_id == 2 && <div style={{float: 'none'}} class="emoji love">
                                     <div class="icon" data-title="love"></div>
                                  </div>}
                                  {comment.reaction_id == 16 && <div style={{float: 'none'}} class="emoji haha">
                                     <div class="icon" data-title="haha"></div>
                                  </div>}
                                  {comment.reaction_id == 3 && <div style={{float: 'none'}} class="emoji wow">
                                     <div class="icon" data-title="wow"></div>
                                  </div>}
                                  {comment.reaction_id == 7 && <div style={{float: 'none'}} class="emoji sad">
                                     <div class="icon" data-title="sad"></div>
                                  </div>}
                                  {comment.reaction_id == 8 && <div style={{float: 'none'}} class="emoji angry">
                                     <div class="icon" data-title="angry"></div>
                                  </div>}
                               </div>
                            </div>

                        </div>
                    </li>))}
                </ul>

            </li>))}
            </ul>

            </div>
        </div>,
        footer: () => <div>
        </div>,
      },
      {
        label: 'Data',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>

                    {this.state.post.post_type === 'existing' && <div>
                        <div class="row">
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Page Type')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.group && this.state.post.group.group_type}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Comments')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.poeple_comments.length : ''}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Comment Group')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.settings.comment_group}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Reply Count')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.replied_people.length : ''}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Status')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.status}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Locked Pump')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.locked_pump ? "Yes" : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('submitted_post_id')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post._id}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('last status')}
                                        {this.state.post.submitted_post && this.state.post.submitted_post.last_check_reaction_date && <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && (moment(this.state.post.submitted_post.last_check_reaction_date)).fromNow()}</span>}
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Detected')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? 'Yes' : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Profile')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.user ? `${this.state.post.user.first_name} ${this.state.post.user.last_name}` : 'No Profile'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Group')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.group ? `${this.state.post.group.name}` : 'No Group'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div>{this.props.t('Embed will only show if post is public')}</div>
                              {<iframe
                                src={"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNetanyahu%2Fposts%2F"+this.state.post.post_url_id+"&show_text=false&width=500"} 
                                width="500" 
                                height="500" 
                                style={{
                                  border: 'none', 
                                  overflow: 'hidden'
                                }} 
                                scrolling="no" 
                                frameborder="0" 
                                allowfullscreen="true" 
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                              ></iframe>}
                            </div>
                        </div>
                    </div>}


                    {this.state.post.post_type === 'new' && <div>
                        <div class="row">
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Likes')}
                                        <span className="badge badge-primary badge-pill badge-blue">TODO</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Comments')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.poeple_comments.length : ''}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Reactions')}
                                        <span className="badge badge-primary badge-pill badge-blue">TODO</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Link Clicks')}
                                        <span className="badge badge-primary badge-pill badge-blue">TODO</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Share Count')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.share_count : ''}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Has Posted')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.posted ? 'Yes' : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Posted At')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? (moment(this.state.post.submitted_post.created_at)).fromNow() : ''}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Last Comment')}
                                        <span className="badge badge-primary badge-pill badge-blue">TODO</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Reply Count')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? this.state.post.submitted_post.replied_people.length : ''}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Automatic Post')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.automatic_post ? 'Yes' : 'No'}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul className="list-group" style={{color: "black"}}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Status')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.status}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Locked Pump')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post.locked_pump ? "Yes" : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('submitted_post_id')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && this.state.post.submitted_post._id}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Last check if pending')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && (moment(this.state.post.submitted_post.last_check_status_date)).fromNow()}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('last status')}
                                        {this.state.post.submitted_post && this.state.post.submitted_post.last_check_reaction_date && <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post && (moment(this.state.post.submitted_post.last_check_reaction_date)).fromNow()}</span>}
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Detected')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.submitted_post ? 'Yes' : 'No'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Post Type')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.post && this.state.post.post.type}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Profile')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.user ? `${this.state.post.user.first_name} ${this.state.post.user.last_name}` : 'No Profile'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Group')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.group ? `${this.state.post.group.name}` : 'No Group'}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {this.props.t('Campaign')}
                                        <span className="badge badge-primary badge-pill badge-blue">{this.state.post.campaign ? `${this.state.post?.campaign?.name}` : 'No Campaign'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>}
        </div>,
        footer: () => <div>
        </div>,
      },
    ];

    render() {
    	const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_user_${this.props.match.params.rand2}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-user-popup',
        loading: !this.state.post,
        // loading: false,
      });
      return (<div></div>);



        return (
		  <div className="overlay">
            <style>{'body {overflow: hidden;}'}</style>
              {this.state.post ? <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.t('View Post')}</h2>

					<SwitchRouter>
						<Route path='/:lang/schedule/automatic/:interval/:period?'>
						</Route>
						<Route path='/'>
							<a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
						</Route>
					</SwitchRouter>

                  </div>

					{this.state.post.submitted_post && this.state.post.submitted_post._errors && this.state.post.submitted_post._errors.map((error) => ( <div className="alert alert-danger" role="alert">
						{error}
					</div>))}

					<hr/>


<div className="" role="group" aria-label="Basic example">
  {this.state.post.submitted_post && this.state.post.submitted_post.status.indexOf('pending') > -1 && <button type="button" className="btn btn-warning" onClick={this.check_if_pending.bind(this)}>{this.props.t('Check if still pending')}</button>}
  

  {!this.state.post.posted && <button type="button" className="btn btn-warning" onClick={this.post_now.bind(this)}>{this.props.t('Post Now')}</button>}
  {this.state.post.posted && <button type="button" className="btn btn-danger" onClick={this.post_now.bind(this)}>{this.props.t('Post Now')}</button>}



  <button type="button" className="btn btn-warning" onClick={this.refresh_reply.bind(this)}>{this.props.t('Refresh Reply')}</button>
  
  <button type="button" className="btn btn-warning" onClick={this.reply_now.bind(this)}>{this.props.t('Reply')}</button>
  
  <button type="button" className="btn btn-warning" onClick={this.refresh_status.bind(this)}>{this.props.t('Check status')}</button>
  

  {this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && <button onClick={this.pump_existing_post.bind(this)} type="button" className="btn btn-warning">{this.props.t('Pump Post')}</button>}
  {this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && <button onClick={this.delete_post.bind(this)} type="button" className="btn btn-warning">{this.props.t('Delete Post')}</button>}
  {this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && <button onClick={this.random_comment.bind(this)} type="button" className="btn btn-warning">{this.props.t('Random Comment')}</button>}
  
  {this.state.post.submitted_post && this.state.post.submitted_post.status === 'published' && this.state.post.settings.should_edit && <button onClick={this.trigger_edit.bind(this)} type="button" className="btn btn-warning">{this.props.t('Trigger Edit')} {this.state.post.edited && <span>({this.props.t('Again - already edited')})</span>}</button>}
  
  {this.state.post.submitted_post && this.state.post.submitted_post.locked_comment && <button onClick={this.unlock_comment.bind(this)} type="button" className="btn btn-warning">{this.props.t('Unlock Comments')}</button>}
  
  {this.state.post.submitted_post && this.state.post.submitted_post.facebook_post_id && <a target="_blank" href={`https://facebook.com/groups/${this.state.post.group.group_id}/posts/${this.state.post.submitted_post.facebook_post_id}`} type="button" className="btn btn-warning">{this.props.t('Show Post')}</a>}

  {/*this.state.post.posted && <button type="button" className="btn btn-warning" onClick={this.generate_comment_tasks.bind(this)}>{this.props.t('Generate Comment Tasks')}</button>*/}

  {this.state.show !== 'logs' && <button type="button" className="btn btn-warning" onClick={() => { this.setState({show: 'logs'})} }>{this.props.t('Show logs')}</button>}
  {this.state.show !== 'comment_logs' && <button type="button" className="btn btn-warning" onClick={() => { this.setState({show: 'comment_logs'})} }>{this.props.t('Comment logs')}</button>}
  {this.state.show !== 'settings' && <button type="button" className="btn btn-warning" onClick={() => { this.setState({show: 'settings'})} }>{this.props.t('Show Settings')}</button>}
</div>
					<hr/>

{this.state.show === 'settings' && this.state.post.settings && <div className="settings">
    <MuiPickersUtilsProvider utils={DateFnsUtils}>


      <div>
          {this.props.t('Should Delete')}
          <Switch
            classes={this.props.classes}
            checked={this.state.post.settings.should_delete}
            onClick={(e) => { this.state.post.settings.should_delete = !this.state.post.settings.should_delete; this.setState({
              post: this.state.post,
            }) }}
          />
      </div>

      {this.state.post.settings.should_delete && <TimePicker
        label={this.props.t("Delete At")}
        inputVariant="outlined"
        value={this.state.post.settings.delete_at}
        onChange={ (newValue)=> {
            this.state.post.settings.delete_at = newValue;
            this.setState({
                post: this.state.post,
            })
        }}
      />}


        {this.state.post.settings.should_delete_time_after_publish && <div>
          <div>
              {this.props.t('Should Delete Time After Publish')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_delete_time_after_publish}
                onClick={(e) => { this.state.post.settings.should_delete_time_after_publish = !this.state.post.settings.should_delete_time_after_publish; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {this.state.post.settings.should_delete_time_after_publish && <select value={this.state.post.settings.delete_after_hours} onChange={(e) => {
                  this.state.post.settings.delete_after_hours = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
            }}>
                <option value={1}>{this.props.t('hour')}</option>
                <option value={2}>2 {this.props.t('hours')}</option>
                <option value={4}>4 {this.props.t('hours')}</option>
                <option value={6}>6 {this.props.t('hours')}</option>
                <option value={8}>8 {this.props.t('hours')}</option>
                <option value={10}>10 {this.props.t('hours')}</option>
                <option value={12}>12 {this.props.t('hours')}</option>
                <option value={16}>16 {this.props.t('hours')}</option>
                <option value={20}>20 {this.props.t('hours')}</option>
                <option value={24}>{this.props.t('day')}</option>
                <option value={24*2}>2 {this.props.t('days')}</option>
                <option value={24*3}>3 {this.props.t('days')}</option>
                <option value={24*4}>4 {this.props.t('days')}</option>
                <option value={24*5}>5 {this.props.t('days')}</option>
                <option value={24*6}>6 {this.props.t('days')}</option>
                <option value={24*7}>1 {this.props.t('week')}</option>
            </select>}
        </div>}



      <div>
          {this.props.t('Should Pump')}
          <Switch
            classes={this.props.classes}
            checked={this.state.post.settings.should_pump}
            onClick={(e) => { this.state.post.settings.should_pump = !this.state.post.settings.should_pump; this.setState({
              post: this.state.post,
            }) }}
          />
      </div>
      {this.state.post.settings.should_pump && <div>
          {this.props.t('Pump Every Minutes')}
          <input 
            type="number"
            name="pump_every_minutes"
            value={this.state.post.settings.pump_every_minutes}
            min="0"
            onChange={(e) => { 
                this.state.post.settings.pump_every_minutes = e.target.value; 
                this.setState({
                  post: this.state.post,
                })
            }}
          />
      </div>}


      <div>
          {this.props.t('Should Edit')}
          <Switch
            classes={this.props.classes}
            checked={this.state.post.settings.should_edit}
            onClick={(e) => { this.state.post.settings.should_edit = !this.state.post.settings.should_edit; this.setState({
              post: this.state.post,
            }) }}
          />
      </div>
      {this.state.post.settings.should_edit && <div>
          {this.props.t('Edit After Minutes')}
          <input 
            type="number"
            name="edit_after_minutes"
            value={this.state.post.settings.edit_after_minutes}
            min="0"
            onChange={(e) => { 
                this.state.post.settings.edit_after_minutes = e.target.value; 
                this.setState({
                  post: this.state.post,
                })
            }}
          />
      </div>}


      {this.state.post.settings.should_edit && <div>
          {this.props.t('Campaign')}
          <select value={this.state.post.settings.edit_campaign} onChange={(e) => {
                this.state.post.settings.edit_campaign = e.target.value; 
                this.setState({
                  post: this.state.post,
                })
          }}>
              <option></option>
              {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option>)}
          </select>

              <div className="select-edit-posts">
                {this.state.posts.map((post) => (
                    <React.Fragment>

                      <span className={`position-relative select-edit-post ${post._id == this.state.post.settings.edit_post ? 'selected' : ''}`} onClick={() => {
                            this.state.post.settings.edit_post = post._id; 
                            this.setState({
                              post: this.state.post,
                            })
                      }}>
                        <span className="text-background d-block m-1 border border-2 border-secondary text-center position-relative post" >

                                {(!post.type || post.type === 'image') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'video') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'album') && 
                                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                }

                                {(post.type === 'text-background' || post.type === 'text') && 
                                  <span className="text-background">
                                     {post.post_content}
                                  </span>
                                }
                        </span>



                    </span>

                    </React.Fragment>
                ))}
               </div>

      </div>}



    </MuiPickersUtilsProvider>


  <div>
    <button onClick={this.save_settings.bind(this)} className="btn btn-warning">{this.props.t('Save')}</button>
  </div>
  <br/>
  <br/>
</div>}



{this.state.show === 'logs' && <div><h2>Logs</h2><div className="container timeline-container">
    <div className="row">
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title">Timeline</h6>
                    <div id="content">
                        <ul className="timeline">
                            {this.state.logs.map((log) => (<li className="event" data-date={this.fromNow(log.created_at)}>
                                <h3>
                                	{log.user && <img width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + log.user._id} g="img/user1.png" alt=""/>}
                                	{log.action}
                                </h3>
                                <p>{log.message}</p>
                                <p><a target="blank" href={log.image_url}><img src={log.image_url} style={{maxWidth: "100%"}}/></a></p>
                            </li>))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>}

{this.state.show === 'comment_logs' && <div>
<h2>{this.props.t('Comment Logs')}</h2>

<ul style={{"text-align": "left"}}>
{this.state.comments && this.state.comments.filter((comment) => {
    return !comment.parent_comment;
}).map((comment) => (<li>
    <div>{this.fromNow(comment.post_at)}</div>
    <div>
        {comment.user && <img onClick={this.post_comment_now.bind(this, comment._id)} style={{opacity: comment.posted ? '1' : '0.5'}} width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + comment.user} g="img/user1.png" alt=""/>}
        {comment.text}
    </div>
    <ul>
        {this.state.comments.filter((child_comment) => {
            return child_comment.parent_comment === comment._id;
        }).map((comment) => (<li>
            <div>
                {comment.user && <img onClick={this.post_comment_now.bind(this, comment._id)} style={{opacity: comment.posted ? '1' : '0.5'}} width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + comment.user} g="img/user1.png" alt=""/>}
                {comment.text}

                <div class="emoji-section" style={{width: '90%'}}>
                   <div class="emoji-container" style={{height: 'auto'}}>
                      {comment.reaction_id == 1 && <div style={{float: 'none'}} class="emoji like">
                         <div class="icon" data-title="Like"></div>
                      </div>}
                      {comment.reaction_id == 2 && <div style={{float: 'none'}} class="emoji love">
                         <div class="icon" data-title="love"></div>
                      </div>}
                      {comment.reaction_id == 16 && <div style={{float: 'none'}} class="emoji haha">
                         <div class="icon" data-title="haha"></div>
                      </div>}
                      {comment.reaction_id == 3 && <div style={{float: 'none'}} class="emoji wow">
                         <div class="icon" data-title="wow"></div>
                      </div>}
                      {comment.reaction_id == 7 && <div style={{float: 'none'}} class="emoji sad">
                         <div class="icon" data-title="sad"></div>
                      </div>}
                      {comment.reaction_id == 8 && <div style={{float: 'none'}} class="emoji angry">
                         <div class="icon" data-title="angry"></div>
                      </div>}
                   </div>
                </div>

            </div>
        </li>))}
    </ul>

</li>))}
</ul>

</div>}


				 </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
		  </div>
        );
    }
}

PostPopup.propTypes = {};

PostPopup.defaultProps = {};

export default PostPopup;



